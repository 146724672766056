a[href$=".pdf"] {
	&::before {
		background-image: url("../img/pdf.svg");
	}
}
a[href$=".doc"],
a[href$=".docx"] {
	&::before {
		background-image: url("../img/word.svg");
	}
}
a[href$=".xlsx"],
a[href$=".xls"] {
	&::before {
		background-image: url("../img/excel.svg");
	}
}
a[href$=".jpg"],
a[href$=".jpeg"],
a[href$=".png"],
a[href$=".svg"]{
	&::before {
		background-image: url("../img/image.png");
	}
}


@import '../variables';
$files-margin-bottom: 50px;
$files-item-width: 100%;
$files-item-width-sm: 100%;
$margin: 10px;
$border: 2px;
$border-color: $base-color;
$item-padding: 20px;
$item-background-color: #fff;
$item-type-padding: 50px;
$item-text-color: $text;

.files__wrapper {
	display: flex;
	flex-wrap: wrap;
	margin: auto;
	margin-bottom: $files-margin-bottom;
}
.files__item {
	width: calc(#{$files-item-width} - #{$margin});
	margin-right: $margin;
	border: $border solid $border-color;
	padding: $item-padding;
	background-color: $item-background-color;
	margin-bottom: $margin;
	display: flex;
	align-items: center;
	transition: all 250ms ease-in-out;
	position: relative;
	color: $item-text-color;
	border-radius: 5px;
	a,
	div {
		color: $item-text-color;
		text-decoration: none !important;
	}
	&:hover {
		transform: translate(0, -5px);
		//box-shadow: 0 7px 5px 0 rgba(0, 0, 0, .2);
	}
	@media (max-width: $bp-sm - 1) {
		width: 100%;
		margin-right: 0;
	}
}
.files__item-file-type {
	width: 100%;
	position: relative;
	padding: 0 $item-type-padding;
	font-weight: 700;
	word-break: break-word;
	&::before {
		position: absolute;
		content: "";
		top: 50%;
		left: 0;
		width: 30px;
		height: 30px;
		background-position: center;
		background-size: contain;
		background-repeat: no-repeat;
		transform: translate(0, -50%);
	}

}
