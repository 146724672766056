@import "../../variables";

@media (min-width: $nav-bp) {
	#mainMenu {
		display: flex;
		align-items: center;
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		li a {
			color: $text;
			line-height: 1.4;
		}
		.header__wrapper {
			flex-grow: 1;
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 15px 0;
		}
		.header__important-info {
			display: flex;
			align-items: center;
		}
		.header__phone {
			margin-right: 15px;
			display: flex;
			align-items: center;
			color: $base-color;
			&:before {
				display: block;
				content: "";
				width: 40px;
				height: 40px;
				background-image: url("../img/phone.svg");
				background-size: contain;
				background-repeat: no-repeat;
				background-position: center;
				margin-right: 10px;
			}
			@media screen and (max-width: $bp-lg - 1) {
				margin-right: 8px;
				&:before {
					width: 30px;
					height: 30px;
					margin-right: 8px;
				}
			}
			@media screen and (max-width: $bp-md - 1) {
				display: none;
			}
		}
		.btn-edookit {
			display: flex;
			align-items: center;
			padding: 10px 26px;
			border: 2px solid #fff;
			margin: 0;
			margin-left: 20px;
			ul {
				align-items: unset;
				text-align: left;
			}
			// a {
			// 	color: #000;
			// }
			&:before {
				content: "";
				display: block;
				width: 30px;
				height: 30px;
				background-image: url("../img/edookit.svg");
				background-size: contain;
				background-repeat: no-repeat;
				background-position: center;
				margin-right: 8px;
			}
			&:hover {
				box-shadow: unset;
				background-color: transparent;
				// &::before {
				// 	filter: invert(1);
				// }
				a {
					&:before {
						display: none;
					}
				}
			}
			@media screen and (max-width: $bp-lg - 1) {
				padding: 10px 18px;
				&:before {
					width: 25px;
					height: 25px;
				}
			}
			@media screen and (max-width: $bp-md - 1) {
				padding: 10px 10px;
				margin-left: 5px;
				
			}
		}
		nav {
			flex-grow: 1;
			display: flex;
			// UL settings
			ul {
				// Main list
				display: flex;
				margin: auto;
				margin-right: 0;
				align-items: center;
				& > li {
					position: relative;
					&.w-submenu > a::after {
						content: "";
						width: .7rem;
						height: .7rem;
						border-bottom: 2px solid;
						border-right: 2px solid;
						margin-left: 8px;
						transform: rotate(45deg) translate(-3px);
					}
					&.w-submenu:hover > a:after {
						//transform: rotate(180deg);
					}
					a {
						display: flex;
						align-items: center;
						padding: 5px 10px;
						font-weight: 700;
						color: #fff;
						transition: none;
						margin-right: 10px;
						border: 2px solid transparent;
						border-radius: 0;
						transition: all 250ms ease-in-out;
						font-size: 1.7rem;
						position: relative;
						&:before {
							position: absolute;
							content: "";
							width: 40px;
							height: 40px;
							top: 50%;
							left: 50%;
							background-image: url("../img/star2.svg");
							transform: translate(-50%, -50%) scale(0);
							z-index: -1;

							transition: all 250ms ease-in-out;
						}
						&:hover {
							//color: #000;
							&:before {
								transform: translate(-50%, -50%) scale(1);
							}
						}
					}
					@media screen and (max-width: $bp-lg - 1) {
						a {
							padding: 5px 10px;
							font-size: 1.6rem;
							margin-right: 0;
						}
					}
					@media screen and (max-width: $bp-md - 1) {
						a {
							padding: 5px 10px;
						}
					}
					// &.w-submenu > a:after {
					// 	content: " ";
					// 	display: inline-block;
					// 	padding: 0 0 0 3px;
					// 	width: 1em;
					// 	height: 1em;

					// 	background-size: 12px auto;
					// 	vertical-align: middle;
					// 	transition: all 250ms ease-in-out;
					// }
				}
				& > li > ul {
					// First level
					//background-color: $base-color;
					position: absolute;
					top: 100%;
					left: 50%;
					min-width: 280px;
					transform: translate(-50%, 0);
					width: auto;
					flex-direction: column;
					opacity: 0;
					pointer-events: none;
					height: auto;
					max-height: calc(100vh - 170px);
					border-radius: 0;
					background-color: $base-color;
					overflow: auto;
					align-items: unset;
					// &:last-child {
					// 	left: unset;
					// 	right: 0;
					// 	&::before {
					// 		left: unset;
					// 		right: 60px;
					// 	}
					// }

					a {
						transition: padding-left 160ms ease-in;
						&:before {
							display: none;
						}
					}
					a:hover {
						padding-left: 26px;
					}
					& > li {
						border-radius: 0;
						color: #fff;
						position: relative;
						padding: 5px 0;
						border-top: 1px solid rgba(255, 255, 255, .35);
						&:first-child {
							border-top: unset;
						}
						a {
							display: block;
							border-radius: 0;
							color: inherit;
							padding-left: 14px;
							padding-right: 14px;
						}
						&:hover > a {
							color: #fff;
						}
						&.w-submenu > a:after {
							content: "";
							position: absolute;
							right: 10px;
							left: auto;
							top: 1em;
							width: 10px;
							height: 10px;
							border-bottom: 3px solid #fff;
							border-right: 3px solid #ffffff;
							transform: translate(0, -50%) rotate(45deg);
							background-image: none;
							padding: 0;
							transition: transform 140ms ease-in;
						}
						&.w-submenu > a::before {
							content: "";
							position: absolute;
							right: 18px;
							left: auto;
							top: 1.2em;
							width: 16px;
							height: 2px;
							background-color: #fff;
							background-image: none;
							padding: 0;
							opacity: 0;
							transform: translate(-10px, 0);
							transition: all 140ms ease-in;
						}
						&.w-submenu:hover > a:after {
							transform: rotate(45deg);
						}
						&.w-submenu[data-status="clicked_once"] {
							& > a::after {
								transform: translate(-10px, -50%) rotate(-45deg);
							}
							& > a::before {
								opacity: 1;
								transform: translate(-10px);
							}

							a {
								background-color: $base-color;
								// &:hover {
								// 	background-color: $base-color;
								// }
								&:hover:after {
									transform: translate(0, -50%) rotate(-45deg);
								}
								&:hover:before {
									transform: translate(0);
								}
							}
						}
					}
					li > ul {
						// Sec level
						position: relative;
						display: none;
						opacity: 1;
						background-color: transparent;
						border-left: 9px solid #eee;
						& > li {
							width: 100%;
							color: #fff;
							a {
								position: relative;
								z-index: 10000;
								white-space: normal;
								color: inherit;
								padding-left: 20px;
								&:hover {
									background-color: $sec-color;
								}
								&:before {
									display: none;
								}
							}
						}
						li ul {
							// Third level
							display: none;
							border-left: 12px solid #fff;
						}
					}
				}
				// Trigger for main menu
				li:hover,
				//li:focus-within,
				li:focus {
					& > ul {
						opacity: 1;
						pointer-events: all;
					}
				}
			}
		}
	}
}

#mainMenu {
	.header__logo {
		height: 60px;
		max-width: 100%;
		img {
			height: 100%;
			width: 100%;
			object-fit: contain;
		}
		@media screen and (max-width: $bp-lg - 1) {
			height: 50px;
		}
	}
}
