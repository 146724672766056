@import '../variables';
$footer-background: $base-color;
$footer-padding: 60px;
$footer-copyright-padding: 20px;
$footer-col-width: 33.333%;
$footer-col-width-sm: 100%;
$footer-col-margin: 40px;
$text-color: #fff;
$heading-color: #fff;
$margin: 8px;
.footer {
	background: $footer-background;
	padding: $footer-padding 0;
}
.footer__wrapper {
	display: flex;
	flex-wrap: wrap;
	position: relative;
	z-index: 2;
}
.footer__col {
	h4 {
		margin-bottom: 25px;
		font-size: 3.2rem;
		text-align: left;
	}
	width: calc(#{$footer-col-width} - #{$footer-col-margin});
	margin-right: $footer-col-margin;
	&.large {
		width: calc(40% - #{$footer-col-margin});
	}
	@media (max-width: $bp-sm - 1) {
		width: $footer-col-width-sm;
		margin-right: 0;
		margin-bottom: 20px;
		&.large {
			width: $footer-col-width-sm;
		}
	}
	h4,
	h3 {
		color: $heading-color;
	}
	a,
	div {
		color: $text-color;
	}
}
//Logo
.footer__logo {
	margin-bottom: $margin;
	img {
		margin-bottom: 15px;
		width: 80%;
		@media (max-width: $bp-sm - 1) {
			width: 100%;
		}
	}
}
.footer__description {
	width: 70%;
	@media (max-width: $bp-sm - 1) {
		width: 100%;
	}
}
.footer__socials {
	display: flex;
	.footer__socials {
		display: block;
		width: 50px;
		height: 50px;
		margin-right: 8px;
		img {
			width: 100%;
			height: 100%;
		}
	}
	@media (max-width: $bp-sm - 1) {
		.footer__socials {
			width: 30px;
			height: 30px;
		}
	}
}
//Menu
.footer__menu {
	display: flex;
	flex-direction: column;
}
.footer__menu-item {
	margin-bottom: $margin;
}
//Contact
.footer__contact {
	display: flex;
	flex-direction: column;
	//text-align: center;
}
.footer__contact-item {
	margin-bottom: $margin;
	display: flex;
	align-items: center;
	margin: auto;
	flex-wrap: wrap;
	strong {
		margin-right: $margin;
	}
	img {
		margin-right: $margin;
	}
}
.footer__address,
.footer__phone,
.footer__email {
	margin-bottom: 25px;
	display: flex;
	align-items: center;
	&:before {
		display: block;
		content: "";
		width: 25px;
		height: 25px;

		background-size: contain;
		background-repeat: no-repeat;
		background-position: center;
		margin-right: 10px;
	}
}
.footer__address {
	&::before {
		background-image: url("../img/address.svg");
	}
}
.footer__phone {
	&::before {
		background-image: url("../img/phone-footer.svg");
	}
}
.footer__email {
	&::before {
		background-image: url("../img/email.svg");
	}
}

.footer-copyright {
	background-color: $footer-background;
}
.footer-copyright__content {
	//text-align: center;
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	padding: 30px 0;
	border-top: 1px solid #ffffff;
}
.footer-copyright__content-item {
	color: $text-color;
	@media (max-width: $bp-sm - 1) {
		display: flex;
		flex-direction: column;
		width: 100%;

		&:last-child {
			order: -1;
			margin-bottom: 20px;
		}
		a {
			margin-right: 0;
			margin-bottom: 10px;
			text-align: left;
		}
	}
	a {
		margin-right: 15px;
		color: $text-color;
	}
}

.footer__menu {
	ul {
		li {
			position: relative;
			padding-left: 15px;
			margin-bottom: 15px;
			&::before {
				position: absolute;
				content: "";
				width: 6px;
				height: 6px;
				top: .4em;
				left: 0;
				background-color: $sec-color;
				border-radius: 50%;
			}
		}
	}
	@media (max-width: $bp-sm - 1) {
		width: 100%;
		//text-align: center;
		margin-bottom: 20px;
	}
}
.footer__menu-wrapper {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	@media (max-width: $bp-sm - 1) {
		justify-content: center;
	}
}
