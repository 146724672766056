@import '../variables';
//Slick
.slick-arrow {
	position: absolute;
	left: 10px;
	top: 50%;
	width: 50px;
	height: 50px;
	background-image: url(../img/arrow.svg);
	background-color: transparent;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	z-index: 10;
	border: none;
	outline: none;
	transition: all 250ms ease-in-out;
	cursor: pointer;
	//transform: translate(0, 50%);
	&.arrow-next {
		background-image: url(../img/arrow.svg);
		left: unset;
		right: 10px;
		transform: rotate(180deg);
	}
}
