@import '../variables';

.about-us__wrapper {
	display: flex;
	align-items: center;
	position: relative;
	z-index: 2;
	margin-bottom: 70px;
	flex-wrap: wrap;
	@media (max-width: $bp-xs - 1) {
		margin-bottom: 30px;
	}
}
.about-us__content {
	width: calc(55% - 25px);
	margin-right: 25px;
	@media (max-width: $bp-md - 1) {
		width: calc(55% - 25px);
	}
	@media (max-width: $bp-sm - 1) {
		width: 100%;
	}
}
.about-us__heading-intro {
	color: $base-color;
	text-transform: uppercase;
	font-weight: 700;
	margin-bottom: 8px;
	display: block;
}
.about-us__heading {
	text-align: left;
	font-size: 6.8rem;
	margin-bottom: 45px;
	@media screen and (max-width: $bp-xs - 1) {
		font-size: 3.6rem;
		margin-bottom: 15px;
	}
}
.about-us__description {
	width: 80%;
	margin-bottom: 35px;
	font-weight: 400;
	text-align: justify;
	@media (max-width: $bp-xs - 1) {
		width: 100%;
	}
}
.about-us__image {
	width: 45%;
	height: 600px;
	img {
		width: 100%;
		height: 100%;
		object-fit: contain;
		border-radius: 0;
	}

	@media (max-width: $bp-sm - 1) {
		width: 100%;
		margin-right: 0;
		margin-bottom: 30px;
		height: 300px;
		order: -1;
	}
	@media (max-width: $bp-xxs - 1) {
		height: 250px;
	}
}

.about-us {
	position: relative;
}

.about-us__image-absolute {
	position: absolute;
	&.--comet {
		left: 5%;
		top: 5%;
		@media (max-width: $bp-xl - 1) {
			left: 3%;
		}
		@media (max-width: $bp-sm - 1) {
			left: 0;
			top: 0;
		}
		@media (max-width: $bp-xs - 1) {
			display: none;
		}
	}
	&.--star2 {
		right: 2%;
		top: 30%;
		@media (max-width: $bp-xxs - 1) {
			display: none;
		}
	}
	&.--star3 {
		left: 40%;
		bottom: 20%;
		@media (max-width: $bp-xs - 1) {
			left: 60%;
		}
		@media (max-width: $bp-xxs - 1) {
			display: none;
		}
	}
	&.--star4 {
		left: 5%;
		top: 45%;
		@media (max-width: $bp-sm - 1) {
			left: 0;
		}
		@media (max-width: $bp-xs - 1) {
			left: 80%;
		}
	}
	&.--rocket2 {
		right: 0%;
		bottom: 12%;
		width: 180px;
		@media (max-width: $bp-xl - 1) {
			width: 140px;
			bottom: 3%;
		}
		@media (max-width: $bp-sm - 1) {
			width: 140px;
			bottom: 7%;
		}
	}
}
